import React from 'react';
import {navigateTo} from 'gatsby-link'

import Layout from '../components/layout'
import Jumbotron from '../components/jumbotron'
import ContentSlide from '../components/content-slide'
import LogoCarousel from '../components/logo-carousel';

import Background from '../images/background_dark.jpg';
import Bathroom from '../images/bathroom_dark.jpg';

const IndexPage = ({location}) => {
	return (
		<Layout path={location.pathname}>		
		
      <Jumbotron
				background={Background}
				title="Eastwood Property"
				subtitle="Construction | Alterations | Extensions"
				mobileText="Established in 1996, Eastwood Property constructs new buildings. We also extend and alter existing buildings for a wide variety of clients."
				button="View Projects"
				btnOnClick={() => navigateTo('/projects')}
				btnArrow
			/>

      <ContentSlide
				title="Our Clients"
				text="Eastwood Property works for a variety of private architects and local governments. Some of our clients include:"
				dark
			/>

			<LogoCarousel/>

			<ContentSlide
				full
				background={Bathroom}
				title="What do we do?"
				text={<span>
					Sporting pavillions<br/>
					Public conveniences<br/>
					Schools<br/>
					Architectural designed residences<br/>
					Structural defect rectification<br/>
					Tilt panel construction<br/>
					Sheds<br/>
					Heritage restorations<br/>
					Commercial fit outs<br/>
					Television studios and sets<br/>
					Controlled environments<br/>
					and so much more..</span>}
				button="Learn more about us"
				btnOnClick={() => navigateTo('/about')}
				btnArrow
			/>

    </Layout>
	);
};

export default IndexPage;
