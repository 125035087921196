import React, {Component} from 'react'
import injectSheet from 'react-jss'


import Button from './button'

const styles = {
  background: {
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'scroll',
		height: '100vh',
		width: '100%',
		minWidth: '240px',
		minHeight: '500px',
    '@media (max-width: 1024px)': {
      backgroundAttachment: 'scroll'
    },
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      backgroundAttachment: 'fixed'
    }
	},
	jumbotron: {
		top: 0,
		position: 'absolute',
		minHeight: '500px',
		height: '100vh',
		width: '100%',
		minWidth: '240px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'column',
		transition: 'all 0.2s',
		'@media (max-width: 767px)': {
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
			padding: '2em'
		}
	},
	title: {
		color: 'white',
		textTransform: 'uppercase',
		letterSpacing: '2px',
		margin: '0em 1em 0.25em 1em',
		textAlign: 'center',
		transition: 'all 0.2s',
		'@media (max-width: 767px)': {
			textAlign: 'left',
			margin: 0,
			fontSize: '3em'
		},
		'@media (max-width: 367px)': {
			fontSize: '10vw'
		}
	},
	subtitle: {
		color: 'white',
		textTransform: 'uppercase',
		letterSpacing: '8px',
		fontSize: '0.75em',
		fontWeight: '600',
		'@media (max-width: 767px)': {
			display: 'none'
		}
	},
	spacer: {
		height: '2em',
		'@media (max-width: 767px)': {
			height: '1em'
		}
	},
  mobileText: {
		display: 'none',
		fontSize: '1.125em',
		color: 'white',
		'@media (max-width: 767px)': {
			display: 'block'
		}
	},
}

class Jumbotron extends Component {
  render() {
    const {classes, background, title, subtitle, mobileText, button, btnOnClick, btnArrow} = this.props
    return(
      <div>
          <div className={classes.background} style={{backgroundImage: 'url(' + background + ')'}}/>
          <div className={classes.jumbotron}>
            <div className={classes.spacer}></div>
            <h1 className={classes.title}>{title}</h1>
            <h3 className={classes.subtitle}>{subtitle}</h3>
            <div className={classes.spacer}></div>
            <h3 className={classes.mobileText}>{mobileText}</h3>
            <div className={classes.spacer}></div>
            {(button ? <Button onClick={btnOnClick}>{button} {(btnArrow ? <span>&nbsp;&nbsp;&#x25BA;</span> : '' )}</Button> : '' )}
          </div>
      </div>
    )
  }
}

export default injectSheet(styles)(Jumbotron)
