import React, {Component} from 'react'
import injectSheet from 'react-jss'

import Button from './button'
import HeaderText from './header-text'

const styles = {
  content: {
		padding: '3em',
		'@media (max-width: 767px)': {
			padding: '2em'
		},
    position: 'relative',
    minWidth: '240px'
	},
  dark: {
    backgroundCOlor: '#222222'
  },
  background: {
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'scroll',
		height: '100vh',
		width: '100%',
		minWidth: '240px',
		minHeight: '500px',
		//filter: 'brightness(0.7) contrast(1.1)',
    '@media (max-width: 1024px)': {
      backgroundAttachment: 'scroll'
    },
    '@media screen and (-webkit-min-device-pixel-ratio:0)': {
      backgroundAttachment: 'fixed'
    }
	},
	contentContainer: {
		minHeight: '100vh',
		position: 'relative',
		height: '100%'
	},
	contentBackground: {
		position: 'absolute',
		height: '100%',
  },
  spacer: {
		height: '2em',
		'@media (max-width: 767px)': {
			height: '1em'
		}
	}
}

class ContentSlide extends Component {
  render() {
    const {classes, title, text, background, button, btnOnClick, btnArrow, full, dark} = this.props
    return(
      <div className={(full ? classes.contentContainer : '')}>
        {(full ? <div className={classes.background + ' ' + classes.contentBackground} style={{backgroundImage: 'url(' + background + ')'}}></div> : '')}
        <div className={classes.content + ' ' + (dark ? classes.dark : '')}>
          <HeaderText>{title}</HeaderText>
          <h3>{text}</h3>
          <div className={classes.spacer}></div>
			    {(button ? <Button onClick={btnOnClick}>{button}{(btnArrow ? <span>&nbsp;&nbsp;&#x25BA;</span> : '')}</Button> : '')}
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(ContentSlide)
